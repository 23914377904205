import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";

import { ENV } from "../config/env";

// import { _login } from "@utils/auth";

function onResponse(response: AxiosResponse) {
  response.data = response.data?.data;
  return response;
}

function onError(error: any) {
  // const { response } = error;
  // if (response?.status === 401) {
  //   _login("_blank");
  // }

  return Promise.reject(error);
}

export const rtbInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

rtbInstance.interceptors.request.use((config) => {
  const domain =
    window.location.hostname === "localhost"
      ? "localhost"
      : ENV.GTL_APP_URL.toString().replace(/(^\w+:|^)\/\//, "");
  const idToken = Cookies.get(`token_${domain}`);

  if (idToken) {
    config.headers.Authorization = `Bearer ${idToken}`;
  }
  return config;
});

rtbInstance.interceptors.response.use(onResponse, onError);
