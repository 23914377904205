import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { getMe } from "@api/rtb/private/user";
import { Avatar, Box, CircularProgress } from "@mui/material";
import { PublicPaths } from "@routers/path/PublicPath";
import { iziRoute } from "@routers/routes";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { _login } from "@utils/auth";
import { IziCrypto } from "@utils/crypto";

import { useUserStore } from "@contexts/user.state";

import { ENV } from "@config/env";

import Button from "./Button";

export const LoginBtn = () => {
  const { user, setUser } = useUserStore();
  const getMeQuery = useQuery({
    queryFn: getMe,
    queryKey: ["getMe"],
    staleTime: Infinity,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (!getMeQuery.isFetched) {
      return;
    }
    if (getMeQuery.data?.data) {
      localStorage.setItem("user", JSON.stringify(getMeQuery.data.data));
      localStorage.removeItem("recent_path");
      setUser(getMeQuery.data.data);
    }
    if (getMeQuery.isError) {
      localStorage.removeItem("user");
      setUser(null);
    }
  }, [getMeQuery.isFetched]);

  const onClick = async () => {
    if (!user) {
      return _login();
    }
    setUser(null);
    const domain =
      window.location.hostname === "localhost"
        ? "localhost"
        : ENV.GTL_APP_URL.toString().replace(/(^\w+:|^)\/\//, "");
    Cookies.remove(`token_${domain}`, {
      domain,
    });
    localStorage.removeItem("user");
    await getMeQuery.refetch();
    navigate(`${PublicPaths.landing}`);
  };
  const btnLabel = useMemo(() => {
    if (getMeQuery.fetchStatus === "idle") {
      return user?.username ? "Logout" : "Login";
    }
    return null;
  }, [getMeQuery.fetchStatus, user]);

  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
      {user && (
        <Button
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            ":hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
          onClick={() => (window.location.href = `${ENV.RTB_APP_URL}/dashboard`)}
        >
          <Avatar src={user?.avatar} />
        </Button>
      )}
      <Button
        variant="contained"
        sx={{
          borderRadius: "10px",
          textTransform: "unset",
        }}
        onClick={onClick}
      >
        {getMeQuery.isFetching && <CircularProgress color="inherit" size={24} />}
        {btnLabel}
      </Button>
    </Box>
  );
};
