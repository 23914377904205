import { Navigate } from "react-router-dom";

import loadable from "@loadable/component";

import PostJob from "@pages/post-job";

import { IRoute, IziRoute } from "@utils/IziRoute";

import { ProtectedPaths, PublicPaths } from "./path/PublicPath";

const GatherlanceLanding = loadable(() => import("@pages/gatherlance-landing"));
const Terms = loadable(() => import("@pages/policy/Terms"));
const Privacy = loadable(() => import("@pages/policy/Privacy"));
const GatherlanceLandingFreelanceView = loadable(
  () => import("@pages/gatherlance-extra-landing/pages/FreelanceView")
);
const GatherlanceLandingEmployeeView = loadable(
  () => import("@pages/gatherlance-extra-landing/pages/EmployeeView")
);
const JobSearch = loadable(() => import("@pages/job-search"));
const RedirectApplyPage = loadable(
  () => import("@pages/redirect-apply/RedirectApplyPage")
);
const JobDetails = loadable(() => import("@pages/job-details"));

const CallbackAuth = loadable(() => import("@pages/auth/CallbackAuth"));

const TITLE = "Lanceboard";

export const routes: IRoute[] = [
  {
    id: PublicPaths.policyTermsPage,
    path: PublicPaths.policyTermsPage,
    index: false,
    private: false,
    element: Terms,
  },
  {
    id: PublicPaths.policyTermsPage,
    path: PublicPaths.policyPrivacyPage,
    index: false,
    private: false,
    element: Privacy,
  },
  {
    id: PublicPaths.policyTermsPage,
    path: PublicPaths.policyTermsPage,
    index: false,
    private: false,
    element: Terms,
  },
  {
    id: PublicPaths.policyTermsPage,
    path: PublicPaths.policyPrivacyPage,
    index: false,
    private: false,
    element: Privacy,
  },
  {
    id: PublicPaths.landing,
    path: PublicPaths.landing,
    title: TITLE,
    index: false,
    private: false,
    element: GatherlanceLanding,
    children: [
      {
        id: PublicPaths.freelance,
        path: PublicPaths.freelance,
        title: TITLE,
        private: false,
        element: GatherlanceLandingFreelanceView,
        outsideLayout: true,
        children: [
          {
            id: PublicPaths.search,
            path: PublicPaths.search,
            title: TITLE,
            index: false,
            private: false,
            outsideLayout: true,
            element: () => <JobSearch />,
          },
        ],
      },
      {
        id: PublicPaths.employee,
        path: PublicPaths.employee,
        title: TITLE,
        private: false,
        element: GatherlanceLandingEmployeeView,
        outsideLayout: true,
      },
      {
        id: PublicPaths.JobDetails,
        path: PublicPaths.JobDetails,
        private: false,
        outsideLayout: true,
        element: JobDetails,
      },
    ],
  },
  {
    id: PublicPaths.apply,
    path: PublicPaths.apply,
    title: TITLE,
    index: false,
    private: false,
    element: RedirectApplyPage,
  },
  {
    id: ProtectedPaths.postJob,
    path: ProtectedPaths.postJob,
    title: TITLE,
    index: false,
    private: false,
    outsideLayout: true,
    element: () => <PostJob />,
  },
  {
    id: PublicPaths.callbackAuth,
    path: PublicPaths.callbackAuth,
    title: TITLE,
    index: false,
    private: false,
    element: CallbackAuth,
  },

  {
    id: "any",
    path: "*",
    title: TITLE,
    index: false,
    private: false,
    element: () => <Navigate to={PublicPaths.landing} />,
  },
];

export const iziRoute = new IziRoute({
  routes,
});
