import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";

import { ReactComponent as SuccessJobImage } from "@assets/images/job_success.svg";

interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  jobPath: string;
  showDraft?: boolean;
}

const SuccessCreateJobDialog = ({ isOpen, onClose, jobPath, showDraft }: IProps) => {
  const {
    palette: { common },
  } = useTheme();
  const navigate = useNavigate();
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      sx={{ ".MuiDialog-paper": { borderRadius: 6 } }}
    >
      <DialogContent>
        <Stack alignItems={"center"} pt={4} pb={8}>
          <SuccessJobImage />
          <Typography fontSize={30} fontWeight={600} color={"#1A202C"}>
            {showDraft
              ? "Your job is created successfully! "
              : "Woohoo! Your job is posted"}
          </Typography>
          <Typography fontSize={16} fontWeight={400} color={"#718096"}>
            {showDraft
              ? "You can check your job in Quest Management of Project "
              : "Your job listing is now live and ready for proposals!"}
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderColor: common.bellflowerBlue,
              color: common.gray5,
              borderRadius: 100,
              mt: 8,
            }}
            onClick={() => {
              location.href = jobPath;
            }}
          >
            {showDraft ? "Go to Quest Management" : "View job post"}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessCreateJobDialog;
