import { ENV } from "@config/env";

export function _login(target?: string) {
  // const key = IziCrypto.generateKey();
  // localStorage.setItem("auth_key", key);
  // localStorage.setItem("recent_path", location.pathname + location.search);
  const url = `${ENV.RTB_APP_URL}/sign-in?${new URLSearchParams({
    redirectTo: `${ENV.GTL_APP_URL}`,
  }).toString()}`;
  if (target === "_blank") {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
}
