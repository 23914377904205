import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import useGetOwnTraditonalRoundtable, {
  getJoinedProjects,
} from "@api/rtb/useGetJoinedProjects";
import { INITIAL_PROJECT_NAME_REGEX } from "@constants/index";
import {
  Stack,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import FormInput from "@components/FormInput";

import { RoundtableTypeEnum } from "@@types/type";

import { useUserStore } from "@contexts/user.state";

import { PostJobFormType } from "../types";

interface IProps {}

const PROJECT_TYPE = [
  RoundtableTypeEnum.SELF,
  RoundtableTypeEnum.FREELANCER,
  RoundtableTypeEnum.EXTERNAL_SELF,
  RoundtableTypeEnum.EXTERNAL_FREELANCER,
];

const TEAM_TYPE = [RoundtableTypeEnum.TEAM];

const ProjectDisplay = ({ ...componentProps }: IProps) => {
  const user = useUserStore((state) => state.user);
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<PostJobFormType>();

  const { data } = useQuery({
    queryKey: ["getJoinedProject"],
    queryFn: () =>
      getJoinedProjects({ userId: user?.id, types: TEAM_TYPE, page: 0, pageSize: 100 }),
  });

  const { data: joinedRtb = [] } = data || {};

  const { data: projectList } = useGetOwnTraditonalRoundtable({
    userId: user?.id,
    types: PROJECT_TYPE,
    page: 0,
    pageSize: 100,
  });

  const projectOptions = projectList.map((project) => ({
    value: project.id,
    text: project.title,
  }));

  const rtbOptions = useMemo(
    () =>
      joinedRtb.map((project) => ({
        value: project.id,
        text: project.title,
      })) || [],
    [joinedRtb]
  );

  const joinedRtbProjects = useMemo(
    () =>
      joinedRtb.flatMap((rtb) =>
        rtb.projects.map((project) => ({ ...project, rtbId: rtb.id }))
      ),
    [joinedRtb]
  );

  const projectWatch = watch("project");
  useEffect(() => {
    if (rtbOptions.length) {
      setValue("project.rtbId", rtbOptions?.[rtbOptions.length - 1]?.value);
    }
  }, [joinedRtb]);

  const handleSelectProject = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    setValue("project.id", value);
    setValue(
      "project.name",
      projectOptions.find((project) => project.value === value)?.text || ""
    );
    let rtbValue = rtbOptions?.[rtbOptions.length - 1]?.value; // default my rtb
    joinedRtbProjects.forEach((project) => {
      if (project.id === value) {
        rtbValue = project?.rtbId;
      }
    });
    setValue("project.rtbId", rtbValue);
  };

  // Set default project:
  useEffect(() => {
    if (!projectOptions?.length || !joinedRtbProjects?.length) {
      return;
    }
    const defaultPrj =
      projectOptions.find((p) => INITIAL_PROJECT_NAME_REGEX.test(p.text)) ??
      projectOptions[0];

    setValue("project.id", defaultPrj.value);
    setValue("project.name", defaultPrj.text);

    let rtbValue = rtbOptions?.[rtbOptions.length - 1]?.value; // default my rtb
    joinedRtbProjects?.forEach((project) => {
      if (project.id === defaultPrj.value) {
        rtbValue = project?.rtbId;
      }
    });
    setValue("project.rtbId", rtbValue);
  }, [projectList, rtbOptions, joinedRtbProjects]);

  return (
    <Stack gap={1.5}>
      <Typography fontSize={18} fontWeight={700}>
        Project
      </Typography>
      <FormControl error={Boolean(errors.project?.id)}>
        <Controller
          control={control}
          name="project.id"
          render={({ field }) => (
            <Select
              displayEmpty
              {...field}
              onChange={handleSelectProject}
              sx={{ height: 40, borderRadius: 2 }}
            >
              <MenuItem value="">New project</MenuItem>
              {projectOptions.map((project) => (
                <MenuItem key={project.value} value={project.value}>
                  {project.text}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{errors.project?.id?.message}</FormHelperText>
        <Typography fontSize={18} fontWeight={700} mt={1}>
          Project name
        </Typography>
      </FormControl>
      {!projectWatch?.id && (
        <FormInput
          sx={{
            ".MuiInputBase-root": {
              borderRadius: 2,
            },
            "& input": { background: "white" },
          }}
          defaultValue=""
          control={control}
          name="project.name"
          {...(!!projectWatch?.id && { value: "" })}
          label={"Project name"}
          disabled={!!projectWatch?.id}
          size="small"
          error={!!errors?.project?.name?.message}
          helperText={errors?.project?.name?.message}
        />
      )}
      <Typography fontSize={18} fontWeight={700} mt={1}>
        Project folder/Teamspace
      </Typography>
      <Controller
        control={control}
        name="project.rtbId"
        render={({ field }) => (
          <Select
            displayEmpty
            disabled={!!projectWatch?.id}
            {...field}
            sx={{ height: 40, borderRadius: 2, mb: 2 }}
          >
            {rtbOptions.map((rtb) => (
              <MenuItem key={rtb.value} value={rtb.value}>
                {rtb.text}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Stack>
  );
};

export default ProjectDisplay;
