export const PublicPaths = {
  landing: "/",
  projectPage: "/project",
  landingPage: "/project",
  searchJobPage: "/search",
  jobDetailsPage: "/job-details/:jobId",
  publishedProjects: "/published-projects",
  publishedProjectDetail: "/published-projects/:projectId",
  product: "/product",
  productDetail: "/product/:projectId",
  policyTermsPage: "/policy/terms",
  policyPrivacyPage: "/policy/privacy",
  gatherlance: "/gatherlance",
  freelance: "/freelance",
  employee: "employee",
  search: "search",
  apply: "/apply",
  JobDetails: "job-details/:jobId",
  callbackAuth: "callback-auth",
} as const;

export const ProtectedPaths = {
  postJobPage: "/post-job",
  postJob: "post-job",
};
