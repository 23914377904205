import { ProposalStatus } from "@dto/proposal";

export * from "./datetime";

const NOT_APPLICABLE = "N/A";
const ADMIN_SITE_PREFIX = "admin";

const ROUND_TABLE_STATUS = {
  ACTIVE: "Active",
  DELETED: "Deleted",
};

const USER_STATUS = {
  NEW: "new",
  CONFIRMED: "confirmed",
};

const ROUND_TABLE_TYPE = {
  TEAM: "TEAM",
  PROJECT: "PROJECT",
};

const VOTE_MODE_VALUE = {
  SIMPLE_VOTE: "Simple Vote",
  WEIGHT_COMMON_VOTE: "Weight Common Vote",
  ELECTORS_VOTE: "Electors Vote",
};

enum VoteModeEnum {
  SIMPLE_VOTE = "SIMPLE_VOTE",
  WEIGHT_COMMON_VOTE = "WEIGHT_COMMON_VOTE",
  ELECTORS_VOTE = "ELECTORS_VOTE",
}

enum RoundTableAttendeeEnum {
  SUB_ROUNDTABLE = "SUB-ROUNDTABLE",
  MEMBER = "MEMBER",
}

enum EventProposalReminder {
  NONE = "NONE",
  FIFTEEN_MINUTE = "FIFTEEN_MINUTE",
  THIRTY_MINUTE = "THIRTY_MINUTE",
  FORTYFIVE_MINUTE = "FORTYFIVE_MINUTE",
  ONE_HOUR = "ONE_HOUR",
  ONE_DAY = "ONE_DAY",
}

const EventLogStatusLabel: Partial<Record<keyof typeof ProposalStatus | any, string>> = {
  ONGOING: "On-going",
  COMPLETED: "Done",
};

const GENDER = {
  FEMALE: "Female",
  MALE: "Male",
} as const;

const ROUND_TABLE_STATUS_OPTIONS = [
  {
    label: "All",
    value: null,
  },
  {
    label: ROUND_TABLE_STATUS.ACTIVE,
    value: ROUND_TABLE_STATUS.ACTIVE,
  },
  {
    label: ROUND_TABLE_STATUS.DELETED,
    value: ROUND_TABLE_STATUS.DELETED,
  },
];

const ROUND_TABLE_TYPE_OPTIONS = [
  {
    label: "All",
    value: null,
  },
  {
    label: ROUND_TABLE_TYPE.TEAM,
    value: ROUND_TABLE_TYPE.TEAM,
  },
  {
    label: ROUND_TABLE_TYPE.PROJECT,
    value: ROUND_TABLE_TYPE.PROJECT,
  },
];

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const IMG_REGEX = /\.(jpg|jpeg|png|gif|bmp|webp|ico)$/i;
const DOCUMENT_REGEX = /\.(pdf|doc|docx)$/i;

const INITIAL_PROJECT_NAME_REGEX = /\w*'s\sProject/gim;

export type RoundTableStatusType =
  (typeof ROUND_TABLE_STATUS)[keyof typeof ROUND_TABLE_STATUS];
export type RoundTableTypeOptionType =
  (typeof ROUND_TABLE_TYPE)[keyof typeof ROUND_TABLE_TYPE];

export const STATUS_CODE = {
  ACCEPTED: 202,
};

export const LANDING_SECTION_KEY = {
  popular: "popularProject",
  postProject: "postYourOwnProject",
  category: "category",
  recommend: "recomendedProjects",
  waitList: "waitList",
};

export {
  NOT_APPLICABLE,
  ADMIN_SITE_PREFIX,
  EMAIL_REGEX,
  IMG_REGEX,
  INITIAL_PROJECT_NAME_REGEX,
  DOCUMENT_REGEX,
  ROUND_TABLE_STATUS,
  VOTE_MODE_VALUE,
  ROUND_TABLE_STATUS_OPTIONS,
  ROUND_TABLE_TYPE,
  ROUND_TABLE_TYPE_OPTIONS,
  GENDER,
  EventLogStatusLabel,
  VoteModeEnum,
  RoundTableAttendeeEnum,
  EventProposalReminder,
  USER_STATUS,
};
